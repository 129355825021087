/* MOBILE */
$(document).on("click", "#search-icon", function (e) {
  e.preventDefault();


  if ($("#search_widget").hasClass("active")) {
    $("#search_widget").removeClass("active");
  } else {
    $("#search_widget").addClass("active").find("input[type=text]").focus();
  }

});

$("#search_widget").on("click", ".close", function (e) {
  e.preventDefault();
  $("#search_widget").removeClass("active");
});
