import "../../../../css/modules/newproducts.scss";
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/autoplay";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// defult theme
import '@splidejs/splide/css';

// core
// import '@splidejs/splide/css/core';


// export function loadSlideproductSliderUnivers() {
//     console.log('loadSlideproductSliderUnivers');
//     const swiperProductType = new Swiper("#sliderunivers", {
//         modules: [Autoplay],
//         rewind: true,
//         freemode: true,
//         autoplay: {
//             delay: 2000,
//         },

//     });
// }

export function loadSlideproductType() {
    console.log('loadSlideproductType');
    const swiperProductType = new Swiper("#typeshop", {
        modules: [Autoplay],
        rewind: true,
        freemode: true,
        hashNavigation: true,
        autoplay: {
            delay: 3000,
        },
        autoHeight: true,
        slidesPerView: 'auto',
        // navigation: {
        //     nextEl: ".swiper-button-next",
        //     prevEl: ".swiper-button-prev",
        // },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 2.2,
                spaceBetween: 10,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2.2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 10,
            },
            // when window width is >= 640px
            1280: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
            1680: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
        },
    });
}

export function loadSlideGender() {
    console.log('loadSlideGender');
    const swiperGender = new Swiper("#gendershop", {
        modules: [Autoplay],
        rewind: true,
        freemode: true,
        hashNavigation: true,
        autoplay: {
            delay: 3000,
        },
        autoHeight: true,
        // navigation: {
        //     nextEl: ".swiper-button-next",
        //     prevEl: ".swiper-button-prev",
        // },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1.2,
                spaceBetween: 10,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1.2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            // when window width is >= 640px
            1280: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1680: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
        },
    });
}

export function loadSliderReviews() {
    console.log('loadSliderReviews');
    const swiperSliderReviews = new Swiper("#ratingshop", {
        modules: [Navigation, Autoplay],
        rewind: true,
        autoplay: {
            delay: 3000,
        },
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
}

export function loadSliderUnivers() {
    console.log('loadSliderUnivers');



    const swiperSliderUnivers = new Splide('.splide', {
        type: 'loop',
        drag: 'free',
        lazyLoad: 'nearby',
        focus: 0,
        //perPage: 20,
        autoWidth: true,
        autoScroll: {
            speed: 2,
        },
    });

    swiperSliderUnivers.on('overflow', function (isOverflow) {
        if (isOverflow) {
            // Enough slides
            console.log('Enough slides');
        } else {
            // Not enough slides
            console.log('Not enough slides');
        }
    });

    // swiperSliderUnivers.on('overflow', function (isOverflow) {
    //     if (isOverflow) {
    //         // Enough slides
    //         console.log('Enough slides');
    //     } else {
    //         // Not enough slides
    //         console.log('Not enough slides');
    //     }
    // });

    swiperSliderUnivers.mount({ AutoScroll });



    // const swiperSliderUnivers = new Swiper("#sliderunivers", {
    //     modules: [Autoplay],
    //     // freeMode: true,
    //     loop: true,
    //     speed: 1000,
    //     autoplay: {
    //         delay: 1,
    //         disableOnInteraction: false
    //     },
    //     centeredSlides: false,
    //     slidesPerView: "auto",
    //     spaceBetween: 10,
    // });
}


export function loadSlideSliderCollection() {
    console.log('loadSlideSliderCollection');
    const swiperSliderCollection = new Swiper("#slidercollection", {
        slidesPerView: "auto",
        spaceBetween: 20,
    });
}


export function loadSlideReassuranceHome() {
    console.log('loadSlideReassuranceHome');
    const swiperProductType = new Swiper("#reassurance-slider", {
        modules: [Autoplay],
        rewind: true,
        freemode: true,
        hashNavigation: true,
        autoplay: {
            delay: 3000,
        },
        autoHeight: true,
        slidesPerView: 'auto',
        // navigation: {
        //     nextEl: ".swiper-button-next",
        //     prevEl: ".swiper-button-prev",
        // },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            // when window width is >= 640px
            1280: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            1680: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
        },
    });
}
