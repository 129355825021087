
$(document).ready(function () {
    prestashop.blockcart = prestashop.blockcart || {};

    var showModal =
        prestashop.blockcart.showModal ||
        function (modal) {
            var $body = $("body");
            $body.append(modal);
            $body.one("click", "#blockcart-modal", function (event) {
                if (event.target.id === "blockcart-modal") {
                    $(event.target).remove();
                }
            });
        };

    prestashop.on("updateCart", function (event) {
        var refreshURL = $(".blockcart").data("refresh-url");
        var requestData = {};
        if (
            event &&
            event.reason &&
            typeof event.resp !== "undefined" &&
            !event.resp.hasError
        ) {
            requestData = {
                id_customization: event.reason.idCustomization,
                id_product_attribute: event.reason.idProductAttribute,
                id_product: event.reason.idProduct,
                action: event.reason.linkAction,
            };
        }
        if (event && event.resp && event.resp.hasError) {
            prestashop.emit("showErrorNextToAddtoCartButton", {
                errorMessage: event.resp.errors.join("<br/>"),
            });
        }
        $.post(refreshURL, requestData)
            .then(function (resp) {
                var html = $("<div />").append($.parseHTML(resp.preview));
                $(".blockcart").replaceWith($(resp.preview).find(".blockcart"));
                if (resp.modal) {
                    showModal(resp.modal);
                }
            })
            .fail(function (resp) {
                prestashop.emit("handleError", {
                    eventType: "updateShoppingCart",
                    resp: resp,
                });
            });
    });
});