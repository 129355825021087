document.addEventListener("DOMContentLoaded", () => {
  if (window.innerWidth < 1024 && !$('#checkout').length) {
    console.log('import menu');
    import(
      /* webpackChunkName: "menu_mobile" */
      "./ps_mainmenu_mobile.js"
    ).then((menu_mobile) => {
      menu_mobile.loadMenu();
    });
  } else if (!$('#checkout').length) {
    import(
      /* webpackChunkName: "menu_desktop" */
      "./ps_mainmenu_desktop.js"
    ).then((menu_desktop) => {
      $('#top-menu').css('display', 'flex');
    });
  };
});

